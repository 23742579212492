import { Box, Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'

export default function WinnerItem({
    number,
    award,
    name,
    image_profile_path,
    created_at
}) {
    return (
        <Card sx={{ my: 1 }}>
            <CardContent sx={{ display: "flex", gap: 1 }}>
                <img src={image_profile_path ? image_profile_path : "/user.jpg"} style={{
                    maxWidth: 80,
                    minWidth: 80,
                    maxHeight: 80,
                    minHeight: 80,
                    borderRadius: 5,
                    objectFit: "cover"
                }} />
                <Box>
                    <Typography variant='body1' sx={{ fontSize: "1em", fontWeight: 600 }}>{name}</Typography>
                    <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Prêmio: <b>{award}</b></Typography>
                    <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Número da sorte: <b>{number}</b></Typography>
                    <Typography variant='body1' sx={{ fontSize: "0.7em" }}>Data da compra: <b>{moment(created_at).format("DD/MM/YYYY HH:mm")}</b></Typography>
                </Box>
            </CardContent>
        </Card>
    );
}
