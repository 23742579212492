import React from 'react'
import { Box, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ChipStatus from './ChipStatus/ChipStatus';

export default function CardRaffle({
    title,
    description,
    chipTextPromotion,
    slug,
    drawDate,
    image,
    onClick,
    raffle
}) {
    return (
        <Box onClick={() => onClick(slug)} className="cursor-pointer">

            <Card sx={{ position: "relative" }}>
                <LazyLoadImage
                    alt={"Sorteio"}
                    itemProp='image'
                    delayMethod='debounce'
                    fetchPriority='high'
                    style={{
                        maxHeight: 408,
                        minHeight: 408,
                        objectFit: "cover",
                    }}
                    visibleByDefault
                    effect='blur'
                    width={"100%"}
                    src={image}
                    wrapperProps={{
                        // style: { transitionDelay: ".01s" },
                        alt: "Sorteio",
                    }}
                />
                <CardContent sx={{ padding: 1, marginBottom: -2, paddingBottom: -10 }}>
                    <Typography variant='subtitle1' fontWeight={600}>{title}</Typography>
                    <Typography>{description}</Typography>
                    {
                        (chipTextPromotion && raffle.status == "active") && <ChipStatus label={chipTextPromotion} />
                    }
                    {
                        raffle.status !== "active" &&
                        <Chip label="Concluído" />
                    }
                    {
                        drawDate &&
                        <div className='flex justify-end p-2 w-full' >
                            <small style={{ fontSize: 10 }}>{moment(drawDate).format("DD/MM/YYYY HH:mm")}</small>
                        </div>
                    }

                </CardContent>
            </Card>
        </Box>
    )
}
