"use client"
import CardRaffle from "@/components/raffles/card-raffle";
import Header from "@/components/header/header";
import { Box, Container, Skeleton, Stack, Typography } from "@mui/material";
import { useRouter, useSearchParams } from "next/navigation";
import useHttp from "@/hooks/useHttp";
import { Suspense, useEffect, useState } from "react";
import { setCookie } from "cookies-next";
import WinnerItem from "@/components/winners/WinnerItem";


function Home({ jsonData }) {
    const { get } = useHttp();
    const router = useRouter();
    const searchParams = useSearchParams();
    const [winners, setWinners] = useState(jsonData?.winners ?? []);
    const [raffles, setRaffles] = useState(jsonData ?? []);
    const [loading, setLoading] = useState(true);

    const redirectoToId = (slug) => {
        router.push(`/raffle/${slug}`);
    }

    const getRaffles = async () => {
        if (raffles?.title) return;
        setLoading(true);
        let response = await get(`/api/raffles`);
        setRaffles(response);
        setWinners(response?.winners);
        setLoading(false);
    };

    // const getWinners = async () => {
    //     let response = await get(`/api/winners/list`);

    //     setWinners(response?.winners);
    // };

    useEffect(() => {
        if (searchParams.get('i')) {
            setCookie("indicated_by", searchParams.get('i'));
            // router.replace('/', {})
        }

        getRaffles();
    }, []);

    useEffect(() => {
        if (raffles?.title) {
            setLoading(false);
        }
    }, [raffles]);


    return (
        <Stack>
            <Header />
            <Container sx={{
                marginTop: 9,
                maxWidth: "700px!important",
                zIndex: 50,
                background: "#DFE3E8",
                borderRadius: 2,
                py: 2,
                px: 1
            }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant={"h6"} fontWeight={"600"}>⚡ Campanhas</Typography>
                    <small>Escolha sua sorte</small>
                </Box>
                <Box sx={{ mt: 1 }}>
                    {
                        loading ? <SkeletonLoader /> :
                            raffles?.title ? <CardRaffle
                                title={raffles.title}
                                description={raffles.short_description}
                                chipTextPromotion={raffles.chip_text_promotion}
                                drawDate={raffles.draw_date}
                                slug={raffles.slug}
                                image={raffles.image_path}
                                onClick={redirectoToId}
                                raffle={raffles}
                            /> : <></>
                    }
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
                    <Typography variant={"h6"} fontWeight={"600"}>🎉 Ganhadores</Typography>
                    <small>Sortudos</small>
                </Box>
                <Stack sx={{ mt: 2 }}>
                    {
                        winners && winners.map(item => (
                            <WinnerItem
                                key={item.id}
                                {...item}
                            />
                        ))
                    }

                </Stack>
            </Container>

        </Stack>
    );

}

const HomeComponent = (props) => {
    return (<Suspense fallback={<>Loading...</>}><Home {...props} /></Suspense>);
}

const SkeletonLoader = () => {
    return (
        <div style={{
            minHeight: 300
        }}>
            <Skeleton className="rounded-lg" variant="rectangular" width={"100%"} height={300} />
        </div>
    );
}

export default HomeComponent;